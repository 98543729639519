import { createStore } from 'vuex'
import socket from './socket'
import { dataLayer, api, removePhoneNumberCountryPrefix } from '@dpgradio/creative'

export default createStore({
  state() {
    return {
      mode: 'inline',
      subscribed: false,
      isSigningUp: false,
      profile: {},
      shouldConfirmMobile: false,
      signupMoment: null,
      error: null,
    }
  },
  mutations: {
    USER_SIGN_IN_STATUS(state, subscribed) {
      state.subscribed = subscribed
    },
    SIGNED_UP(state) {
      state.subscribed = true
    },
    SET_MODE(state, mode) {
      state.mode = mode
    },
    CHANGE_CONFIRMATION_REQUIREMENT(state, confirmationRequirement) {
      state.shouldConfirmMobile = confirmationRequirement
    },
    PROFILE_INFORMATION_FETCHED(state, profileInformation) {
      state.profile = profileInformation
    },
    SET_SIGN_UP_MOMENT(state, signupMoment) {
      state.signupMoment = signupMoment
    },
    SET_IS_SIGNING_UP(state, status) {
      state.isSigningUp = status
    },
    SET_ERROR(state, error) {
      state.error = error
    },
  },
  getters: {
    localStorageRegisteredKey(state) {
      return `signup_moment_${state.signupMoment.id}_registered`
    },
  },
  actions: {
    async checkUserSignedIn({ state, commit, getters }) {
      if (!state.signupMoment || state.signupMoment.status === 'hidden' || !api.radioToken) {
        return
      }

      const subscribed = localStorage.getItem(getters.localStorageRegisteredKey) || false

      commit('USER_SIGN_IN_STATUS', subscribed)
    },

    async signUp({ state, commit, getters}, item) {
      commit('SET_ERROR', null)
      if (state.isSigningUp) {
        return
      }

      commit('SET_IS_SIGNING_UP', true)

      try {
        await api.request().post(state.signupMoment.apiCallback, item)

        dataLayer.pushEvent('signup_success', item)
        localStorage.setItem(getters.localStorageRegisteredKey, true)
        commit('SIGNED_UP')
      } catch (e) {
        dataLayer.pushEvent('signup_failure', item)
        if (e.message.includes('401')) {
          commit('SET_ERROR', "Probleem bij het aanmelden. Probeer de app even opnieuw te starten.")
        } else {
          commit('SET_ERROR', "Er is iets misgegaan bij het aanmelden. Probeer het later nog eens.")
          console.error(e)
        }
        throw e
      } finally {
        commit('SET_IS_SIGNING_UP', false)
      }
    },

    setSignUpMoment({ dispatch, commit, state }, signupMoment) {
      const oldId = state.signupMoment?.id

      commit('SET_SIGN_UP_MOMENT', signupMoment)

      if (oldId !== signupMoment.id) {
        dispatch('checkUserSignedIn')
      }
    },

    setMode({ commit }, mode) {
      commit('SET_MODE', mode)
    },

    async fetchProfileInformation({ commit }) {
      try {
        commit('SET_ERROR', null)
        const profileInformation = await api.members.me()
        commit('PROFILE_INFORMATION_FETCHED', profileInformation)
      } catch (e) {
        if (e.message.includes('401')) {
          commit('SET_ERROR', "Probleem bij het ophalen van je profielinformatie. Probeer de app even opnieuw te starten.")
        } else {
          commit('SET_ERROR', "Er is iets misgegaan bij het ophalen van je profielinformatie. Probeer het later nog eens.")
          console.error(e)
        }
        throw e
      }
    },

    async initConfirmationRequirement({ commit, dispatch }) {
      const confirmedMobile = localStorage.getItem('confirmedMobile')

      if (!confirmedMobile) {
        await dispatch('fetchProfileInformation')
      }

      commit('CHANGE_CONFIRMATION_REQUIREMENT', !confirmedMobile)
    },

    async confirmMobile({ state, commit }, mobile) {
      if (removePhoneNumberCountryPrefix(state.profile.mobile) !== removePhoneNumberCountryPrefix(mobile)) {
        await api.members.updateProfile({ mobile })
      }

      localStorage.setItem('confirmedMobile', true)
      commit('CHANGE_CONFIRMATION_REQUIREMENT', false)
    },
  },
  modules: {
    socket,
  },
})
