<template>
  <div class="c-subscribe">
    <div v-if="showMobileConfirmation">
      <MobileConfirmation @confirm="onConfirmMobile"></MobileConfirmation>
    </div>
    <div v-else-if="showQuestions">
      <QuestionForm @save="onQuestionSubmit"></QuestionForm>
    </div>
    <div v-else-if="subscribed">
      <Subscribed></Subscribed>
    </div>
    <div v-else>
      <h1>{{ duration }}</h1>

      <SignUpButton :disabled="buttonDisabled" :copy="copy.button" @signup="signup" />
      <p v-if="error" class="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MobileConfirmation from './MobileConfirmation.vue'
import Subscribed from './Subscribed.vue'
import SignUpButton from '../SignUpButton.vue'
import QuestionForm from '../questionForm/QuestionForm.vue'
import { authentication } from '@dpgradio/creative'

export default {
  name: 'Subscribe',
  components: { SignUpButton, Subscribed, MobileConfirmation, QuestionForm },
  data() {
    return {
      showMobileConfirmation: false,
      showQuestions: false,
      subscribed: false,
      answers: null,
    }
  },
  computed: {
    ...mapState({
      signupMoment: (state) => state.signupMoment,
      shouldConfirmMobile: (state) => state.shouldConfirmMobile,
      isSigningUp: (state) => state.isSigningUp,
      error: (state) => state.error,
    }),
    buttonDisabled() {
      return this.isSigningUp
    },
    copy() {
      return this.signupMoment.copy?.default
    },
    hasQuestions() {
      return this.signupMoment.additionalQuestions?.length
    },
    duration() {
      const minutes = Math.floor((this.signupMoment.duration % 3600) / 60)
        .toString()
        .padStart(2, '0')
      const seconds = Math.floor(this.signupMoment.duration % 60)
        .toString()
        .padStart(2, '0')
      return `${minutes}:${seconds}`
    },
  },
  methods: {
    async signup() {
      await authentication.require()

      if (this.hasQuestions && !this.answers) {
        this.showQuestions = true
      } else {
        await this.$store.dispatch('initConfirmationRequirement')

        if (this.shouldConfirmMobile) {
          this.showMobileConfirmation = true
        } else {
          await this.$store.dispatch('signUp', { answers: this.answers })
          this.subscribed = true
        }
      }
    },
    async onConfirmMobile() {
      this.showMobileConfirmation = false
      this.shouldConfirmMobile = false
      await this.signup()
    },
    async onQuestionSubmit(answers) {
      this.showQuestions = false
      this.answers = answers
      await this.signup()
    },
  },
}
</script>

<style scoped lang="scss">
input {
  display: block;
  width: 100%;
  font-size: 1.25rem;
  padding: 12px;
  border: 0;
}

.c-input-label {
  padding-bottom: 0.5rem;
}

.c-input {
  box-sizing: border-box;
  width: 100%;
  border-radius: 5px;
  border: solid 2px lightgray;
}
</style>
